window.App.defineController("ToggleIncidentMail", function() {
  "use strict";

  $(".radio_buttons").each (function () {
    $(this).removeClass("required");
  });

  $("input[name='organization[direct_incident_mail]']").each (function () {
    if ($(this)[0].checked) {
      toggleSelector($(this)[0]);
    }
  });

  $("input[name='organization[direct_incident_mail]']").click(function(){
    toggleSelector($(this)[0]);
  });

  function toggleSelector(element) {
    if (element.id === "organization_direct_incident_mail_true") {
      $(".organization_days_incident_inactive_mail").css("display", "none");
    } else {
      $(".organization_days_incident_inactive_mail").removeAttr("style");
    }
  }
});
