window.App.define("DigitForm", function() {
  "use strict";

  return function DigitForm(options) {
    var elements;
    var form = options.form;
    var numberRegex = /[0-9]|\./;
    var resultInput;

    function init() {
      elements = $(".js-digit input", form);
      elements.each(function() {
        $(this).bind("focus", updateFocus);
        $(this).bind("keypress", onKeyPress);
        $(this).bind("keyup", onKeyUp);
        $(this).bind("paste", onPaste);
      });
      resultInput = $(".js-digit-result input", form);

      updateFocus();
    }

    function goBack() {
      var previous = null;
      elements.each(function () {
        var input = $(this);

        if (previous && previous.val() !== "" && input.val() === "") {
          previous.val("");
          updateFocus();
          return false;
        }

        previous = input;
      });
    }

    function isNumber(value) {
      return numberRegex.test(value);
    }

    function onPaste(event) {
      event.stopPropagation();
      event.preventDefault();

      var clipboardData = event.originalEvent.clipboardData.getData("text");
      if (clipboardData.length == 6 && isNumber(clipboardData)){
        elements.each(function (i) {
          var input = $(this);
          input.val(clipboardData[i]);
        });
      }
    }

    function onKeyPress(event) {
      var key = event.keyCode || event.which;
      key = String.fromCharCode(key);

      if (!isNumber(key)) {
        event.returnValue = false;
        if (event.preventDefault) event.preventDefault();
      }
    }

    function onKeyUp(event) {
      var key = event.keyCode || event.which;
      if (key === 8)
        goBack();

      updateFocus();
    }

    function updateFocus() {
      var result = "";
      elements.each(function () {
        var input = $(this);

        if (input.val() === "") {
          if (!input.is(document.activeElement))
            input.focus();

          return false;
        } else {
          result += input.val();
        }
      });

      if (result.length === 6) {
        resultInput.val(result);
        form.submit();
      }
    }

    return {
      init: init
    };
  };
});

