window.App.defineController("Tooltips", function() {
  "use strict";

  var Tooltip = window.App.require("Tooltip");
  $(".js-tooltip-on-hover").each(function () {
    var target = $(this);
    var tooltip = new Tooltip({
      target: target,
      info: target,
      message: target.attr("title"),
      event: "hover"
    });
    tooltip.init();
  });

  $(".js-info-tooltip-on-hover").each(function () {
    var target = $(this);
    var tooltip = new Tooltip({
      target: target,
      message: target.attr("title"),
      event: "hover"
    });
    tooltip.init();
  });
});
