function activateControllers() {
  var scripts = $("html").data("js-scripts") || [];

  for (var i = 0; i < scripts.length; i++) {
    requireController(scripts[i]);
  }
}

function defineController(controllerName, fn) {
  window.App.controllers[controllerName] = fn;
}

function requireController(controllerName) {
  var controller = window.App.controllers[controllerName];
  if (controller) {
    controller();
  }
}

function defineModule(moduleName, fn) {
  window.App.modules[moduleName] = fn;
}

function requireModule(moduleName) {
  var module = window.App.modules[moduleName];
  if (module) {
    return module();
  }
}

window.App = {
  modules: {},
  define: defineModule,
  require: requireModule,
  controllers: {},
  defineController: defineController
};

function init() {
  activateControllers();
}

$(document).ready(init);
