window.App.define("Overlay", function() {
  "use strict";

  var Overlay = function(options) {
    var KeyCodes = window.App.require("KeyCodes");

    if (!options) {
      options = {};
    }
    if (!options.callbacks) {
      options.callbacks = {};
    }

    var elements = {
      body: undefined,
      overlays: []
    };

    function openOverlay() {
      var contentOverlay = $("<div>").addClass("overlay");
      contentOverlay.on("click", function(e) {
        e.preventDefault();
        closeOverlay();
      });

      if (options.className) {
        contentOverlay.addClass(options.className);
      }

      elements.overlays.push(contentOverlay);

      elements.body = $(".js-body");
      elements.body.one("keyup", hideMenuKeyboardCallback);
      elements.body.append(elements.overlays);
      elements.body.click(function() {
        closeOverlay();
      });
    }

    function closeOverlay() {
      if (elements.overlays.length == 0) {
        return;
      }

      while (elements.overlays.length > 0) {
        var overlay = elements.overlays.pop();
        overlay.remove();
      }
      elements.body.off("keyup", hideMenuKeyboardCallback);
      callback("close");
    }

    // Hide overlay on ESC
    function hideMenuKeyboardCallback(e) {
      if (e.keyCode == KeyCodes.specialKeys.ESC) {
        closeOverlay();
      }
    }

    function callback(key) {
      if (!options.callbacks[key]) {
        return;
      }
      options.callbacks[key]();
    }

    return {
      open: openOverlay,
      close: closeOverlay
    };
  };

  return Overlay;
});
