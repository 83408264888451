window.App.defineController("Autosubmit", function() {
  "use strict";

  $(".js-autosubmit-form").each(function () {
    var form = $(this);

    $(".js-autosubmit-button select", form).on("change", function () {
      form.submit();
    });
  });
});
