window.App.defineController("DigitForms", function() {
  "use strict";

  var DigitForm = window.App.require("DigitForm");
  $(".js-digit-form").each(function () {
    var form = $(this);
    var digitForm = new DigitForm({
      form: form
    });
    digitForm.init();
  });
});
