window.App.define("Tooltip", function() {
  "use strict";

  return function Tooltip(options) {
    if (!options) {
      options = {};
    }
    if (!options.offset) {
      options.offset = 10;
    }
    if (!options.info) {
      options.info = $("<span class=\"tooltip-button icon-info\"></span>");
    }

    var Transition = window.App.require("Transition");
    var Random = window.App.require("Random");
    options.id = "tooltip-" + Random.uuid();
    var elements = {
      body: $("body"),
      target: options.target,
      tooltip: undefined,
      info: options.info
    };

    function init() {
      elements.target.append(elements.info);
      elements.target
        .attr("title", "")
        .attr("aria-describedby", options.id);

      elements.info.hover(function(e) {
        e.preventDefault();
        openTooltip();
      }, function() {
        closeTooltip();
      });
    }

    function createTooltip(message) {
      elements.tooltip =
        $("<div class=\"tooltip\" role=\"tooltip\">")
          .attr("aria-describedby", options.id);
      elements.tooltip.append($("<div class=\"tooltip-inner\">").html(message));
    }

    function removeTooltip() {
      elements.tooltip.remove();
    }

    function positionTooltip() {
      var opts = getTooltipPosition();
      elements.tooltip
        .removeClass("tooltip-left tooltip-right")
        .addClass("tooltip-" + opts.placement)
        .css(opts.position);
    }

    function openTooltip() {
      createTooltip(options.message);
      elements.body.append(elements.tooltip);
      positionTooltip();
      elements.tooltip
        .addClass("is-shown")
        .css("display", "block");
    }

    function closeTooltip() {
      elements.tooltip
        .removeClass("is-shown")
        .on(Transition.transitionEnd, function() {
          removeTooltip();
        });
    }

    function getTooltipPosition() {
      var targetWidth = elements.info.outerWidth();
      var targetHeight = elements.info.outerHeight();
      var tooltipWidth = elements.tooltip.outerWidth();
      var tooltipHeight = elements.tooltip.outerHeight();
      var placement = "right";

      var position = elements.info.offset();
      var newTop = position.top + targetHeight / 2 - tooltipHeight / 2;
      var newLeft = position.left + targetWidth + options.offset;
      var newRight = newLeft + tooltipWidth;

      if (newRight > $(document).width()) {
        newLeft = position.left - tooltipWidth - options.offset;
        placement = "left";
      }

      return {
        position: { top: newTop, left: newLeft },
        placement: placement
      };
    }

    return {
      init: init
    };
  };
});
