window.App.defineController("Modals", function() {
  "use strict";

  var Modal = window.App.require("Modal");

  $(".js-modal").each(function () {
    var target = $(this);
    var modal = new Modal({
      target: target,
      message: target.attr("title")
    });
    modal.init();
  });
});
