require.context("../images", true);

require("@rails/ujs").start();

require("chartkick");
require("chart.js");
require("chartjs-plugin-datalabels");

require("cocoon");

require("../src/javascripts/utilities");

require("../src/javascripts/app");
require("../src/javascripts/modules");
require("../src/javascripts/sections");
