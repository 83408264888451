window.App.defineController("OrganizationPositions", function () {
  var trigger = $(".js-organization-positions-trigger");
  var target = $(".js-organization-positions-target");
  var url = trigger.data("url");

  $("select", trigger).on("change", onSelect);

  function onSelect(e) {
    $.ajax({
      url: url.replace(":id", e.target.value),
      type: "GET",
      dataType: "json",
      success: handlePositions
    });
  }

  function handlePositions(positions) {
    $("select", target).html(
      "<option>‹ Maak een keuze ›</option>" +
      Object.keys(positions).map(function (key) {
        return "<optgroup label=\"" + key + "\">" +
            positions[key].map(function(position) {
              return "<option value=\"" + position.id + "\">" + position.name + "</option>";
            }).join("") +
          "</optgroup>";
      }).join("")
    );
  }
});
