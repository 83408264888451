window.App.define("Modal", function() {
  "use strict";

  return function Modal(options) {
    if (!options) {
      options = {};
    }
    if (!options.offset) {
      options.offset = 10;
    }
    if (!options.info) {
      options.info = $("<span class=\"modal-button icon-view\"></span>");
    }

    var Transition = window.App.require("Transition");
    var Random = window.App.require("Random");
    options.id = "modal-" + Random.uuid();
    var elements = {
      body: $("body"),
      target: options.target,
      modal: undefined,
      info: options.info
    };

    function init() {
      elements.target.append(elements.info);
      elements.target
        .attr("title", "")
        .attr("aria-describedby", options.id);

      elements.info.click(function(e) {
        e.preventDefault();
        openModal();
      });
    }

    function createModal(message) {
      elements.modal =
        $("<div class=\"modal\" role=\"modal\">")
          .attr("aria-describedby", options.id);
      elements.modal.click(function (e) {
        var $target = $(e.target);
        if (!$target.closest(".modal-inner").length) {
          closeModal();
        }
      });
      elements.inner = $("<div class=\"modal-inner panel content-wrapper\">");
      elements.close = $("<div class=\"modal-close-button icon-close\" />");
      elements.close.click(function (e) {
        e.preventDefault();
        closeModal();
      });
      elements.modal.append(elements.inner.html(message).append(elements.close));
    }

    function removeModal() {
      elements.modal.remove();
    }

    function openModal() {
      createModal(options.message);
      elements.body.append(elements.modal);
      elements.modal
        .addClass("is-shown")
        .css("display", "flex");
    }

    function closeModal() {
      elements.modal
        .removeClass("is-shown")
        .on(Transition.transitionEnd, function() {
          removeModal();
        });
    }

    return {
      init: init
    };
  };
});
