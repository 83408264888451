window.App.defineController("Datepicker", function() {
  "use strict";

  $(".js-datepicker").each(initPicker);
  $(".js-cocooned-datepicker .add_fields").on("cocoon:after-insert", function () {
    $(".js-datepicker").each(initPicker);
  });

  function initPicker() {
    var input = $(this);

    var value = input.val();
    var isTimepicker = input.data("timepicker") || false;
    var datepicker = input.datepicker(optionsFor(input, isTimepicker)).data("datepicker");

    datepicker.selectDate(parseDateTime(value));
  }

  function parseDateTime(value) {
    var datetimeSegments = value.split(" ");
    var date = parseDate(datetimeSegments[0]);

    if (!date) {
      return;
    }

    if (datetimeSegments.length == 2) {
      var timeSegments = datetimeSegments[1].split(":");
      var hour = timeSegments[0];
      var minute = timeSegments[1];

      date.setHours(hour);
      date.setMinutes(minute);
    }

    return date;
  }

  function parseDate(value) {
    var dateSegments = value.split("-");

    if (dateSegments.length == 3) {
      var year = dateSegments[2].length == 4 ? dateSegments[2] : dateSegments[0];
      var month = parseInt(dateSegments[1]) - 1;
      var day = dateSegments[2].length == 4 ? dateSegments[0] : dateSegments[2];

      return new Date(year, month, day);
    }
  }

  function optionsFor(input, isTimepicker) {
    var options = { language: "nl", timepicker: isTimepicker };

    options.dateFormat = "dd-mm-yyyy";

    options.altField = "#" + input.attr("id") + "[type=\"hidden\"]";
    options.altFieldDateFormat = "yyyy-mm-dd" + (isTimepicker ? " hh:ii" : "");

    if (input.data("months")) {
      options.view = "months";
      options.minView = "months";
      options.dateFormat = "MM yyyy";
    }

    return options;
  }
});
